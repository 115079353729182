import Link from 'next/link'

type SubNavT = {
  data: { name: string; href: string }[] | undefined
}
export default function SubNav({ data }: SubNavT) {
  return (
    <ul className="dt:absolute top-[100%] dt:bg-white dt:-left-[10px] dt:min-w-[240px] dt:border border-white dt:rounded-[6px] dt:py-4">
      {data?.map((subItem) => (
        <li
          key={`sub-${subItem.name}`}
          className="capitalize text-left font-normal border-b border-black_400 dt:border-none"
        >
          <Link
            href={`${subItem.href}`}
            className="flex items-center justify-between text-white dt:text-darkgrey_500 text-[14px] font-medium dt:font-bold leading-[2] dt:leading-none dt:px-6 py-4 dt:py-2 hover:text-blue_400 transition-color duration-300"
          >
            <span className="flex-1 whitespace-nowrap">{subItem.name}</span>
            <span
              className={`material-symbols-outlined text-blue_400 text-[18px] dt:ml-4`}
            >
              east
            </span>
          </Link>
        </li>
      ))}
    </ul>
  )
}
