'use client'
import { useWindowSize } from '@/hooks/useWindowSize'
import HamburgerButton from '../Atoms/HamburgerButton'
import {
  AnimatePresence,
  LazyMotion,
  domAnimation,
  m,
  useMotionValueEvent,
  useScroll,
} from 'framer-motion'
import { useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import Navbar from '../Molecules/Navbar'
import dynamic from 'next/dynamic'
const Logo = dynamic(() => import('../Atoms/Logo'))
const backDropVariants = {
  closed: {
    clipPath: 'circle(10px at calc(100% - 44px) 36px)',
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  open: {
    clipPath: 'circle(2000px at calc(100% - 44px) 36px)',
    transition: {
      type: 'spring',
      stiffness: 50,
    },
  },
}

export default function Header() {
  const path = usePathname()
  const { width } = useWindowSize()
  const { scrollY } = useScroll()
  const [hidden, setHidden] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const prev = scrollY.getPrevious()
    if (latest == 0) {
      setHidden(false)
      return false
    }
    if (prev != undefined && latest > prev && !showMenu) {
      setHidden(true)
    } else {
      setHidden(false)
    }
  })
  useEffect(() => {
    setShowMenu(false)
  }, [path])

  useEffect(() => {
    path !== '/newgraduate' && width > 1351 && setShowMenu(false)
    path == '/newgraduate' && width > 768 && setShowMenu(false)
  }, [path, width])

  useEffect(() => {
    showMenu && width < 1351
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto')
  }, [showMenu, width])

  return (
    <LazyMotion features={domAnimation} strict>
      <header
        className={`top-0 left-0 right-0 z-[999] bg-gradient-to-b from-white to-[rgb(255_255_255_/_0%)] md:bg-none ${path === '/newgraduate' || path.includes('/interview') ? 'sticky' : 'fixed'} ${path === '/newgraduate' && 'bg-white md:py-7 md:pr-6 md:pl-10 md:flex'} dt:py-7 dt:pr-6 dt:pl-10 z-10 dt:flex items-center justify-between transition-transform duration-500 ease-[cubic-bezier(0.1, 0.2, 0.3, 1)] ${hidden ? (path === '/newgraduate' ? '' : '-translate-y-full') : ''}`}
      >
        <div
          className={`flex items-center justify-between py-4 dt:py-0 px-6 dt:pr-0 ${path === '/newgraduate' && 'md:py-0 md:pr-0'} ${showMenu && `bg-darkgrey_900 lg:bg-transparent ${path === '/newgraduate' && 'md:bg-transparent'}`} relative z-[99] transition-colors delay-200`}
        >
          <Logo active={showMenu} />
          <HamburgerButton
            onClick={() => setShowMenu(!showMenu)}
            active={showMenu}
          />
        </div>
        <Navbar active={showMenu} />

        <AnimatePresence initial={false} mode="wait">
          {path !== '/newgraduate'
            ? width < 1351 &&
              showMenu && (
                <m.div
                  variants={backDropVariants}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  className="bg-darkgrey_900 fixed top-0 left-0 w-full h-screen inset-0 z-[5]"
                />
              )
            : width < 769 &&
              showMenu && (
                <m.div
                  variants={backDropVariants}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  className="bg-darkgrey_900 fixed top-0 left-0 w-full h-screen inset-0 z-[5]"
                />
              )}
        </AnimatePresence>
      </header>
    </LazyMotion>
  )
}
