import { NAV_ITEM } from '@/constants/data'
import AnchorButton from '../Atoms/AnchorButton'
import NavItem from '../Atoms/NavItem'
import { LazyMotion, domAnimation, m } from 'framer-motion'
import CopyRightSp from '../Atoms/CopyRightSp'
import { usePathname } from 'next/navigation'

const navVariants = {
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.07,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
}
const navListVariants = {
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.03,
      staggerDirection: -1,
    },
  },
}
const navChildVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -50 },
    },
  },
  hidden: {
    opacity: 0,
    y: 50,
    transition: {
      y: { stiffness: 1000, velocity: -50 },
    },
  },
}
export default function Navbar({ active }: { active: boolean }) {
  const path = usePathname()
  return (
    <LazyMotion features={domAnimation} strict>
      {path !== '/newgraduate' ? (
        <m.nav
          className={`fixed dt:static top-0 dt:top-auto left-0 dt:left-auto w-full h-full dt:w-auto dt:h-auto dt:!opacity-100 ${active ? 'visible' : 'invisible'} dt:!visible  pt-[72px] dt:pt-0 z-[10]`}
          variants={navVariants}
          initial="hidden"
          animate={active ? 'visible' : 'hidden'}
        >
          <div className="dt:flex items-center overflow-y-auto dt:overflow-y-visible overflow-x-hidden dt:overflow-x-visible h-full pb-8 dt:pb-0">
            <m.div
              variants={navListVariants}
              animate={active ? 'visible' : 'hidden'}
              className="block dt:flex items-center px-8 dt:px-0 pt-6 dt:pt-0 dt:!opacity-100"
            >
              {NAV_ITEM.map((item, index) => (
                <NavItem
                  href={item.href}
                  sub={item.children}
                  name={item.name}
                  key={`nav-${index}`}
                  active={active}
                  slug={item.slug}
                />
              ))}
            </m.div>

            {/* PC button  */}
            <div className="hidden dt:flex items-center ml-4">
              <div className="mr-1.5">
                <AnchorButton
                  href="/newgraduate"
                  type="white-border"
                  size="px-4 dt:px-5 py-3 dt:py-4"
                >
                  新卒採用
                </AnchorButton>
              </div>
              <div className="mr-1.5">
                <AnchorButton
                  href="https://hrmos.co/pages/superstudio/jobs/0010018/apply"
                  type="blue"
                  size="px-4 dt:px-5 py-3 dt:py-4"
                  target="_blank"
                >
                  カジュアル面談
                </AnchorButton>
              </div>
              <div className="mr-1.5">
                <AnchorButton
                  href="https://hrmos.co/pages/superstudio/jobs"
                  type="blue"
                  size="px-4 dt:px-5 py-3 dt:py-4"
                  target="_blank"
                >
                  募集要項・エントリー
                </AnchorButton>
              </div>
            </div>
            {/* SP button  */}
            <m.div
              variants={navChildVariants}
              className="px-6 block dt:hidden mt-6 dt:!opacity-100"
            >
              <>
                <m.div className="[&>a]:!block [&>a]:!text-[14px] [&>a]:!text-center mb-4 max-w-[500px] mx-auto">
                  <AnchorButton
                    href="https://hrmos.co/pages/superstudio/jobs"
                    target="_blank"
                    type="blue"
                    size="px-5 py-5 relative"
                  >
                    募集要項を見る
                    <span className="material-symbols-outlined absolute right-6 top-1/2 -translate-y-1/2 text-[18px]">
                      open_in_new
                    </span>
                  </AnchorButton>
                </m.div>
                <m.div className="[&>a]:!block [&>a]:!text-[14px] [&>a]:!text-center max-w-[500px] mx-auto">
                  <AnchorButton
                    href="https://hrmos.co/pages/superstudio/jobs/0010018/apply"
                    target="_blank"
                    type="grey"
                    size="px-5 py-5 relative"
                  >
                    カジュアル面談
                    <span className="material-symbols-outlined absolute right-6 top-1/2 -translate-y-1/2 text-[18px]">
                      open_in_new
                    </span>
                  </AnchorButton>
                </m.div>
              </>
            </m.div>
            {/* Footer nav  */}
            <m.div
              variants={navChildVariants}
              className="block dt:hidden px-8 mt-10 dt:!opacity-100"
            >
              <CopyRightSp />
            </m.div>
          </div>
        </m.nav>
      ) : (
        <m.nav
          className={`fixed md:static top-0 md:top-auto left-0 md:left-auto w-full h-full md:w-auto md:h-auto md:!opacity-100 ${active ? 'visible' : 'invisible'} md:!visible  pt-[72px] md:pt-0 z-[10]`}
          variants={navVariants}
          initial="hidden"
          animate={active ? 'visible' : 'hidden'}
        >
          <div className="md:flex items-center overflow-y-auto md:overflow-y-visible overflow-x-hidden md:overflow-x-visible h-full pb-8 md:pb-0">
            <m.div
              variants={navListVariants}
              animate={active ? 'visible' : 'hidden'}
              className="block md:hidden items-center px-8 md:px-0 pt-6 md:pt-0 md:!opacity-100"
            >
              {NAV_ITEM.map((item, index) => (
                <NavItem
                  href={item.href}
                  sub={item.children}
                  name={item.name}
                  key={`nav-${index}`}
                  active={active}
                  slug={item.slug}
                />
              ))}
            </m.div>
            <div className="hidden md:flex items-center ml-4">
              <div className="mr-1.5">
                <AnchorButton
                  href="/"
                  type="white-border"
                  size="px-4 md:px-5 py-3 md:py-4"
                >
                  中途採用
                </AnchorButton>
              </div>
              <div>
                <AnchorButton
                  href="https://www.career-cloud.asia/26/entry/job/offer/super-studio/detail/99"
                  type="blue"
                  size="px-4 md:px-5 py-3 md:py-4"
                  target="_blank"
                >
                  募集要項を見る
                </AnchorButton>
              </div>
            </div>
            {/* SP button  */}
            <m.div
              variants={navChildVariants}
              className="px-6 block md:hidden mt-6 md:!opacity-100"
            >
              <>
                <m.div className="[&>a]:!block [&>a]:!text-[14px] [&>a]:!text-center mb-4 max-w-[500px] mx-auto">
                  <AnchorButton href="/" type="blue" size="px-5 py-5 relative">
                    中途採用を見る
                    <span className="material-symbols-outlined absolute right-6 top-1/2 -translate-y-1/2 text-[18px]">
                      open_in_new
                    </span>
                  </AnchorButton>
                </m.div>
                <m.div className="[&>a]:!block [&>a]:!text-[14px] [&>a]:!text-center max-w-[500px] mx-auto">
                  <AnchorButton
                    href="https://www.career-cloud.asia/26/entry/job/offer/super-studio/detail/99"
                    type="grey"
                    size="px-5 py-5 relative"
                    target="_blank"
                  >
                    エントリー・募集要項
                    <span className="material-symbols-outlined absolute right-6 top-1/2 -translate-y-1/2 text-[18px]">
                      open_in_new
                    </span>
                  </AnchorButton>
                </m.div>
              </>
            </m.div>
            {/* Footer nav  */}
            <m.div
              variants={navChildVariants}
              className="block md:hidden px-8 mt-10 md:!opacity-100"
            >
              <CopyRightSp />
            </m.div>
          </div>
        </m.nav>
      )}
    </LazyMotion>
  )
}
