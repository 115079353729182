'use client'

import { createHttpLink } from '@apollo/client'
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
} from '@apollo/experimental-nextjs-app-support/ssr'
import { setContext } from '@apollo/client/link/context'

interface ITokenResponse {
  token: string
}

let apiEndpoint = ''

if (typeof window !== 'undefined' && process.env.NODE_ENV == 'development') {
  apiEndpoint = 'http://localhost:3100/graphql'
} else if (process.env.NODE_ENV == 'production') {
  apiEndpoint = `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`
} else {
  apiEndpoint = 'http://ec_force_site:3100/graphql'
}

const isBrowser = typeof window !== 'undefined'
const getCsrfToken = async (): Promise<{
  token: string
  cookies: string[]
}> => {
  const api = isBrowser
    ? `${process.env.NEXT_PUBLIC_FRONTAPI_URL}/api/v1/token`
    : `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/token`
  const response = await fetch(api, { credentials: 'include' })
  const { token } = (await response.json()) as ITokenResponse
  const cookies = isBrowser // browsers use real cookie, and cannot read "Set-Cookie" fields in response header (SSG)
    ? []
    : // node-fetch can't use cookie, so read "Set-Cookie" field in response header (SSR)
      response.headers.get('set-cookie')?.split(/,\s(?=[^,]*?=)/gm) || []

  return { token, cookies }
}

export const setCsrfTokenLink = setContext(async (_, prevContext) => {
  const { token, cookies } = await getCsrfToken()
  const cookie = cookies.join(';')

  return {
    ...prevContext,
    headers: {
      ...prevContext.headers,
      // for node-fetch
      ...(!isBrowser && { cookie }),
      'X-CSRF-TOKEN': token,
    },
  }
})

const terminatedLink = createHttpLink({
  uri: apiEndpoint,
  credentials: 'include',
})

function makeClient() {
  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(),
    link: setCsrfTokenLink.concat(terminatedLink),
  })
}

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  )
}
