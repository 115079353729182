'use client'
import Link from 'next/link'
import SubNav from '../Molecules/SubNav'
import { usePathname } from 'next/navigation'
import { AnimatePresence, LazyMotion, domAnimation, m } from 'framer-motion'
import { useWindowSize } from '@/hooks/useWindowSize'
import { useEffect, useState } from 'react'
import CopyRightSp from './CopyRightSp'

type NavItemT = {
  name: string
  href: string
  sub?: { name: string; href: string }[] | undefined
  active: boolean
  slug: string
}

const backDropVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 0.7,
  },
}
const subNavVariants = {
  closed: {
    opacity: 0,
    y: 20,
  },
  open: {
    opacity: 1,
    y: 0,
  },
}
const subNavSpVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
}
const navItem = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 500, velocity: -50 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 500, velocity: -50 },
    },
  },
}
export default function NavItem({ href, name, sub, active, slug }: NavItemT) {
  const pathName = usePathname()
  const { width } = useWindowSize()
  const [open, setOpen] = useState(false)
  const activeItem =
    (pathName !== '/' && slug.includes(pathName)) || pathName === href
  useEffect(() => {
    setOpen(false)
  }, [pathName, width, active])

  return (
    <LazyMotion features={domAnimation} strict>
      <m.div
        className="group dt:mr-3 last:mr-0 mb-1 dt:mb-0 dt:last:hidden dt:!opacity-100 dt:!transform-none"
        onMouseEnter={() => width > 1350 && setOpen(true)}
        onMouseLeave={() => width > 1350 && setOpen(false)}
        onClick={() => width < 1350 && setOpen(true)}
        variants={navItem}
      >
        {!sub ? (
          <Link
            href={`${href}`}
            className={`block text-[22px] group-last:text-[20px] dt:text-[14px] font-avenirNextBold leading-none dt:text-center py-4 uppercase relative transition-all duration-300 dt:group-hover:text-blue_400 tracking-widest font-bold ${
              activeItem ? 'text-blue_400' : 'text-white dt:text-darkgrey_900'
            } z-[99999]`}
          >
            <span className="relative pr-[15px] dt:pr-0 dt:pl-[10px]">
              {name}
            </span>
          </Link>
        ) : (
          <span
            className={`block text-[22px] group-last:text-[20px] dt:text-[14px] font-avenirNextBold leading-none dt:text-center py-4 uppercase relative transition-all cursor-pointer duration-300 dt:group-hover:text-blue_400 tracking-widest font-bold ${
              activeItem ? 'text-blue_400' : 'text-white dt:text-darkgrey_900'
            } z-[99999]`}
          >
            <span className="relative pr-[15px] dt:pr-0 dt:pl-[10px]">
              {name}
            </span>
          </span>
        )}
        <AnimatePresence>
          {sub != undefined && open && (
            <>
              <m.div
                className="hidden dt:block fixed z-[9999] top-0 left-0 h-[150vh] w-full bg-darkgrey_900 pointer-events-none"
                variants={backDropVariants}
                initial="hidden"
                animate="show"
                exit="hidden"
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              />
              <m.div
                initial="closed"
                variants={width > 1350 ? subNavVariants : subNavSpVariants}
                animate="open"
                exit="closed"
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                className="fixed dt:relative top-[72px] dt:top-auto left-0 dt:left-auto bg-darkgrey_900 dt:bg-transparent w-full dt:w-auto h-[calc(100vh_-_72px)] dt:h-auto z-[999999] pt-10 dt:pt-0 px-8 dt:px-0 overflow-y-auto dt:overflow-y-visible overflow-x-hidden dt:overflow-x-visible pb-8 dt:pb-0"
              >
                <div className="block dt:hidden">
                  <button
                    className="flex items-center mb-8"
                    onClick={(e) => {
                      e.stopPropagation()
                      setOpen(false)
                    }}
                  >
                    <span className="material-symbols-outlined text-blue_400 text-[20px] leading-none">
                      west
                    </span>
                    <span className="text-white uppercase leading-none text-[16px] font-avenir95black ml-2">
                      BACK
                    </span>
                  </button>
                </div>
                <div className="block dt:hidden">
                  <p className="text-white font-avenirNextBold leading-[2] text-[22px] tracking-[2px] uppercase">
                    {name}
                  </p>
                </div>
                <SubNav data={sub} />
                <div className="block dt:hidden mt-16">
                  <CopyRightSp />
                </div>
              </m.div>
            </>
          )}
        </AnimatePresence>
      </m.div>
    </LazyMotion>
  )
}
