import { MotionConfig, m } from 'framer-motion'
import { usePathname } from 'next/navigation'

const variants = {
  parent: {
    closed: {
      scale: 1,
      background: '#6E95FF',
    },
    open: {
      scale: 1.3,
      background: '#ffffff',
    },
  },
  top: {
    open: {
      rotate: ['0deg', '0deg', '45deg'],
      top: ['42%', '50%', '50%'],
      background: '#6E95FF',
    },
    closed: {
      rotate: ['45deg', '0deg', '0deg'],
      top: ['50%', '50%', '42%'],
      background: '#ffffff',
    },
  },
  bottom: {
    open: {
      rotate: ['0deg', '0deg', '-45deg'],
      top: ['48%', '41%', '41%'],
      background: '#6E95FF',
    },
    closed: {
      rotate: ['-45deg', '0deg', '0deg'],
      top: ['41%', '41%', '48%'],
      background: '#ffffff',
    },
  },
}

export default function HamburgerButton({
  active,
  onClick,
}: {
  active: boolean
  onClick: () => void
}) {
  const path = usePathname()

  return (
    <MotionConfig transition={{ duration: 0.3, ease: 'easeInOut' }}>
      <m.button
        variants={variants.parent}
        initial={false}
        animate={active ? 'open' : 'closed'}
        onClick={onClick}
        className={`${path === '/newgraduate' && 'md:hidden'} dt:hidden w-10 h-10 rounded-full appearance-none relative outline-none`}
      >
        <m.span
          variants={variants.top}
          className="h-[1px] w-[18px] bg-white absolute"
          style={{ y: '-50%', left: '50%', x: '-50%', top: '42%' }}
        />
        <m.span
          variants={variants.bottom}
          className="h-[1px] w-[18px] bg-white mt-1 absolute"
          style={{ left: '50%', x: '-50%', top: '48%', y: '-50%' }}
        />
      </m.button>
    </MotionConfig>
  )
}
