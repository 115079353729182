import Link from 'next/link'

type AnchorT = {
  href: string
  type: 'white' | 'white-border' | 'blue' | 'grey' | 'black'
  size: string
  children: React.ReactNode
  target?: string
}

export default function AnchorButton({
  href,
  type,
  size,
  children,
  target,
}: AnchorT) {
  return (
    <Link
      target={`${target ? target : ''}`}
      href={`${href}`}
      className={`transition-all duration-300 rounded-[40px] inline-block ${size} font-medium text-[12px] md:text-[14px] leading-none box-border ${
        type == 'white-border'
          ? 'text-blue_400 bg-white border border-darkgrey_100 hover:bg-darkgrey_500 hover:border-darkgrey_500 hover:text-white'
          : type == 'blue'
            ? 'bg-blue_400 text-white border border-blue_400 hover:bg-darkgrey_900 hover:border-darkgrey_900'
            : type == 'grey'
              ? 'bg-darkgrey_500 text-white border border-darkgrey_500'
              : type == 'black'
                ? 'bg-darkgrey_900 text-white hover:bg-darkgrey_500'
                : 'bg-white text-black hover:text-blue_400'
      } md:hover:shadow-[0_4px_23px_0_rgba(92,111,131,.3)]`}
    >
      {children}
    </Link>
  )
}
