import Link from 'next/link'

export default function CopyRightSp() {
  return (
    <>
      <div className="flex mb-3">
        <Link
          href=""
          className="text-[10px] font-avenirNextRegular uppercase text-white leading-[1.5] xs:leading-none  pr-3 py-1 border-r border-darkgrey_100 last:border-none"
        >
          COOKIE POLICY
        </Link>
        <Link
          href=""
          className="text-[10px] font-avenirNextRegular uppercase text-white leading-[1.5] xs:leading-none pl-3 py-1"
        >
          PRIVACY POLICY
        </Link>
      </div>
      <div className="flex">
        <Link
          href=""
          className="text-[10px] font-avenirNextRegular uppercase text-white leading-[1.5] xs:leading-none pr-3 py-1 border-r border-darkgrey_100 last:border-none"
        >
          CUSTOMER HARASSMENT POLICY
        </Link>
        <Link
          href=""
          className="text-[10px] font-avenirNextRegular uppercase text-white leading-[1.5] xs:leading-none pl-3 py-1"
        >
          INFORMATION SECURITY
        </Link>
      </div>
      <p className="text-[10px] leading-[1.5] xs:leading-none font-avenirNextRegular text-white text-center mt-6">
        &copy; 2024 SUPER STUDIO Inc.
      </p>
    </>
  )
}
